.list-of-partners-icons {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-around;
  max-width: 1200px;
  margin: 0 auto;
  gap: 1rem;

  img {
    width: 160px;
  }
}
