.correct-answers {
  padding: 12px 0;
  font-size: 1.5rem;
  background-color: #c3f532;
  padding: 12px;
  border-radius: 12px;

  b {
    font-size: 2rem;
  }
}

.lesson-side-panel {
  display: flex;
  align-items: baseline;
  justify-content: center;
  gap: 1rem;
}
