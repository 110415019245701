.teaching-approach-flow-wrapper {
  background-color: #e9f1fe;
  margin: 0 auto;

  .image-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1200px;
    margin: 0 auto;
  }

  .font-bold {
    font-weight: bolder;
    color: rgb(82, 95, 225);
  }

  .content-wrapper {
    font-family: "Urbanist", sans-serif;
    font-size: 3.25rem !important;
    line-height: 1.5;
    padding-left: 1.5rem;
    gap: 2rem;
    display: flex;
    flex-direction: column;
    position: absolute;
    font-size: 1.5rem;
    padding: 1rem 3rem;
    max-width: 50%;
  }

  .relative {
    position: relative;
  }
  .flex {
    display: flex;
  }
  .flex-col {
    flex-direction: column;
  }
  .justify-center {
    justify-content: center;
  }

  .step-2 {
    align-items: flex-end;
  }

  .step-3 {
    .content-wrapper {
      margin-top: -100px;
    }
  }
}
