.lesson-exercises-wrapper {
  display: flex;
  align-items: center;
  gap: 1rem;

  span {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  input {
    max-width: 100px;
  }

  .answer-wrapper {
    display: flex;
    align-items: center;
  }

  .info-btn {
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    transition: 0.4s;
    background-color: #eee;
    border-radius: 12px;
    border: 0;
  }

  input {
    background: transparent;
    border-radius: 12px;
    font-weight: 700;

    &.correct {
      background-color: #c3f532;
    }
  }
}
